<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row mr-5 ml-5">
      <div class="col-xxl-3 mb-15">
         <!-- <div class="col-xl-3 col-lg-6"> -->
            <div class="card card-stats mb-4 mb-xl-0" style="background-color: #F4E0E2; box-shadow: 1px 1px 1rem #8b8c89; height: 150px;">
                <div class="card-body position-relative">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <h5 class="row card-title text-uppercase text-black-50 mb-0"><b>PERSEROAN</b></h5>
                            <span id="jml_perseroan" class="row h5 font-weight-bold mb-0">Jumlah Total : {{dPerseroan}}</span>
                        </div>
                        <div class="col-6 text-right" style="width: auto;">
                          <inline-svg src="media/svg/icons/Layout/suitcase.svg" class="my__img" style="width: 100px; height:100px; display: inline-flex;"/>
                        </div>
                    </div>
                </div>
            </div>
        <!-- </div> -->
      </div>
      <div class="col-xxl-3 mb-4">
        <div class="card card-stats mb-4 mb-xl-0" style="background-color: #F1ACAF; box-shadow: 1px 1px 1rem #8b8c89; height: 150px;">
          <div class="card-body position-relative">
            <div class="row justify-content-between">
                <div class="col-6">
                    <h5 class="row card-title text-uppercase mb-0 text-black-50"><b>NASABAH</b></h5>
                    <span id="jml_perseroan" class="h5 font-weight-bold mb-0 row">Jumlah Total : {{dNasabah}}</span>
                </div>
                <div class="col-6 text-right" style="width: auto;">
                    <!-- <div class="icon icon-shape bg-danger text-white rounded-circle shadow"> -->
                       <img src="media/misc/teller.png" class="my__img" style="width: 100px; height:100px; display: inline-flex;"/>
                    <!-- </div> -->
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-3 mb-4">
        <div class="card card-stats mb-4 mb-xl-0" style="background-color: #F4E0E2; box-shadow: 1px 1px 1rem #8b8c89; height: 150px;">
          <div class="card-body position-relative">
            <div class="row justify-content-between">
               <div class="col-7">
                  <h6 class="row card-title text-uppercase mb-0 text-black-50 text-left"><b>Pengurusan Perseroan</b></h6>
                  <span id="akte1" class="row h7 font-weight-bold mb-0 mt-2">Pending : {{dPengurusanAkte.pending}}</span>
                  <span id="akte2" class="row h7 font-weight-bold mb-0">On Progress : {{dPengurusanAkte.progress}}</span>
                  <span id="akte3" class="row h7 font-weight-bold mb-0">Selesai : {{dPengurusanAkte.done}}</span>
                  
              </div>
              <div class="col-5">
                  <div class="icon icon-shape bg-yellow text-white rounded-circle shadow">
                     <img src="media/misc/stamp.png" class="my__img" style="width: 100px; height:100px; display: inline-flex;"/>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-3 mb-4">
        <div class="card card-stats mb-4 mb-xl-0" style="background-color: #F1ACAF; box-shadow: 1px 1px 1rem #8b8c89; height: 150px;">
          <div class="card-body position-relative">
            <div class="row justify-content-between">
               <div class="col-7">
                  <h6 class="row card-title text-uppercase mb-0 text-black-50 text-left"><b>Pengurusan Nasabah</b></h6>
                  <span id="akte1" class="row h7 font-weight-bold mb-0 mt-2">Pending : {{dPengurusanLain.pending}}</span>
                  <span id="akte2" class="row h7 font-weight-bold mb-0">On Progress : {{dPengurusanLain.progress}}</span>
                  <span id="akte3" class="row h7 font-weight-bold mb-0">Selesai: {{dPengurusanLain.done}}</span>
                  
              </div>
              <div class="col-5">
                  <div class="icon icon-shape bg-yellow text-white rounded-circle shadow">
                     <img src="media/misc/contract.png" class="my__img" style="width: 100px; height:100px; display: inline-flex;"/>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-app class="without-min-height" style="display: contents;">
        <div class="row">
          <div class="col-lg-12 col-xxl-12 order-1 order-xxl-12" style="background-color: #F4E0E2;">
            <v-row class="fluid fill-height">
              <v-col>
                <v-sheet height="64">
                  <v-toolbar
                    flat
                  >
                    <v-btn
                      outlined
                      class="mr-4"
                      color="red darken-2"
                      @click="setToday"
                    >
                      Today
                    </v-btn>
                    <v-btn
                      fab
                      text
                      small
                      color="grey darken-2"
                      @click="prev"
                    >
                      <v-icon small>
                        mdi-chevron-left
                      </v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      text
                      small
                      color="grey darken-2"
                      @click="next"
                    >
                      <v-icon small>
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="$refs.calendar">
                      {{ $refs.calendar.title }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu
                      bottom
                      right
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          color="red darken-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span>{{ typeToLabel[type] }}</span>
                          <v-icon right>
                            mdi-menu-down
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="type = 'day'">
                          <v-list-item-title>Day</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'week'">
                          <v-list-item-title>Week</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'month'">
                          <v-list-item-title>Month</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = '4day'">
                          <v-list-item-title>4 days</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-toolbar>
                </v-sheet>
                <v-sheet height="600">
                  <v-calendar
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    :events="events"
                    event-overlap-mode="column"
                    :event-color="getEventColor"
                    :type="type"
                    @click:event="showEvent"
                    @click:more="viewDay"
                    @click:date="viewDay"
                    @change="updateRange"
                  ></v-calendar>
                  <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    width="500px"
                    offset-x
                  >
                    <v-card
                      color="grey lighten-4"
                      width="500px"
                      flat
                    >
                      <v-toolbar
                        :color="selectedEvent.color"
                        dark
                      >
                        <!-- <v-btn icon>
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn> -->
                        <v-toolbar-title>
                          <span v-html="selectedEvent.name"></span>
                        </v-toolbar-title>

                        <v-spacer></v-spacer>
                        <!-- <v-btn icon>
                          <v-icon>mdi-heart</v-icon>
                        </v-btn>
                        <v-btn icon>
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn> -->
                      </v-toolbar>
                      <v-card-text>
                        <span v-html="selectedEvent.deskripsi"></span>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="secondary"
                          @click="selectedOpen = false"
                        >
                          Cancel
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-sheet>
              </v-col>
            </v-row>
            <!-- <Calendar1></Calendar1> -->
            <!-- <ListWidget8></ListWidget8> -->
          </div>
        </div>
      </v-app>
    </div>
    <!--end::Dashboard-->
  </div>
</template>
<style lang="css">
    .without-min-height .v-application--wrap{ min-height: 0px; margin-left: 20px; margin-right: 20px; }
    .my__img {
      height: 120px;
      width: 120px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import storagelocal from "@/core/services/storagelocal.service";
import ApiService from "@/core/services/api.service";
import Services from "@/core/services/notasis-api/Services";
// import ListWidget8 from "@/view/content/widgets/list/Widget8.vue";
// import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
// import Calendar1 from "@/view/pages/vuetify/Calendars.vue"

export default {
  name: "dashboard",
  components: {
    // ListWidget8,
    // StatsWidget7,
    // Calendar1,
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  data() {
    return{
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      dPerseroan: "",
      dNasabah: "",
      dPengurusanAkte: [],
      dPengurusanLain: [],
      minDate: 0,
      maxDate: 0,
      dKalPengerjaan: [],
      dKalPengerjaan1: [],
      dKalPengerjaan2: [],
      dKalPengerjaan3: [],
      dKalPengerjaan4: []
    }
  },
  mounted() {
    this.$refs.calendar.checkChange()
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.load2()
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    async updateRange ({ start, end }) {
      var events = []
      this.minDate = new Date(`${start.date}T00:00:00`)
      this.maxDate = new Date(`${end.date}T23:59:59`)
      await this.getDataKalenderPengerjaan();
      await this.getDataKalenderPengerjaan1();
      await this.getDataKalenderPengerjaan2();
      await this.getDataKalenderPengerjaan3();
      await this.getDataKalenderPengerjaan4();
      
      events.push.apply(events, this.dKalPengerjaan)
      events.push.apply(events, this.dKalPengerjaan1)
      events.push.apply(events, this.dKalPengerjaan2)
      events.push.apply(events, this.dKalPengerjaan3)
      events.push.apply(events, this.dKalPengerjaan4)
      // for (let i = 0; i < this.dKalPengerjaan.length; i++) {
      //   const allDay = this.rnd(0, 3) === 0
      //   events.push({
      //     name: this.dKalPengerjaan[i].nama_client,
      //     start: new Date(this.dKalPengerjaan[i].tgl_mulai),
      //     end: new Date(this.dKalPengerjaan[i].tgl_akhir),
      //     color: this.dKalPengerjaan[i].status_bg,
      //     timed: !allDay,
      //     deskripsi: this.dKalPengerjaan[i].description
      //   })
      // }

      // for (let i = 0; i < events.length; i++) {
      //   const allDay = this.rnd(0, 3) === 0
      //   events[i].timed = !allDay
      //   var min = new Date(events[i].tgl_mulai)
      //   var max = new Date(events[i].tgl_mulai)
      //   min 
      //   max
      //   events.push({
      //     name: this.dKalPengerjaan1[i].nama_client,
      //     start: new Date(this.rnd(min.getTime(), max.getTime()) - (this.rnd(min.getTime(), max.getTime()) % 900000)), //new Date(this.dKalPengerjaan1[i].tgl_mulai),
      //     end: new Date(this.dKalPengerjaan1[i].tgl_akhir),
      //     color: this.dKalPengerjaan1[i].status_bg,
      //     // timed: !allDay,
      //     deskripsi: this.dKalPengerjaan1[i].description
      //   })
      // }

//////////////gw
        // const min = new Date(`${start.date}T00:00:00`)
        // const max = new Date(`${end.date}T23:59:59`)
        // const days = (max.getTime() - min.getTime()) / 86400000
        // const eventCount = this.rnd(days, days + 20)

        // for (let i = 0; i < eventCount; i++) {
        //   const allDay = this.rnd(0, 3) === 0
        //   const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        //   const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        //   const second = new Date(first.getTime() + secondTimestamp)

        //   events.push({
        //     name: this.names[this.rnd(0, this.names.length - 1)],
        //     start: first,
        //     end: second,
        //     color: this.colors[this.rnd(0, this.colors.length - 1)],
        //     timed: !allDay,
        //   })

      for (let i = 0; i < events.length; i++) {
        if(events[i].end == events[i].start)
          events[i].end = new Date(new Date(events[i].end).setMinutes(1))
      }
//////////////gw
      var a = events.groupBy(['start'])
      a
      this.events = events
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    async load2(){
      Promise.all([
                  await this.getDataPerseroan(),
                  ])
      .then(async(results)=>{
        await this.getDataNasabah(),
        await this.getDataPengurusanAkte(),
        await this.getDataPengurusanLain(),
        results
      })
    },
    getDataPerseroan(){
      this.dPerseroan = ""
      return new Promise(resolve => {
        var mydata = {
          UID 		: storagelocal.getLocalStorage('UID'),
          Token		: storagelocal.getLocalStorage('token'),
          Trigger	: 'R',
          // options : this.options,
          Route   : "count_perseroan",
          status  : 1
        }

          Services.PostData(ApiService, "m_perseroan", mydata, async response=>{
            var responseCache = response.total
            this.dPerseroan = responseCache
            resolve(this.dPerseroan);
          }, err =>{
            err
          })
      });
    },
    getDataNasabah(){
      this.dNasabah = ""
      return new Promise(resolve => {
        var mydata = {
          UID 		: storagelocal.getLocalStorage('UID'),
          Token		: storagelocal.getLocalStorage('token'),
          Trigger	: 'R',
          // options : this.options,
          Route   : "count_nasabah",
          status  : 1
        }

          Services.PostData(ApiService, "m_nasabah", mydata, async response=>{
            var responseCache = response.total
            this.dNasabah = responseCache
            resolve(this.dNasabah);
          }, err =>{
            err
          })
      });
    },
    getDataPengurusanAkte(){
      this.dPengurusanAkte = []
      return new Promise(resolve => {
        var mydata = {
          UID 		: storagelocal.getLocalStorage('UID'),
          Token		: storagelocal.getLocalStorage('token'),
          Trigger	: 'R',
          // options : this.options,
          Route   : "count_pengerjaan",
          id_pengurusan: 1
        }

          Services.PostData(ApiService, "t_pengerjaan", mydata, async response=>{
            var responseCache = response.data[0]
            this.dPengurusanAkte = responseCache
            resolve(this.dPengurusanAkte);
          }, err =>{
            err
          })
      });
    },
    getDataPengurusanLain(){
      this.dPengurusanLain = []
      return new Promise(resolve => {
        var mydata = {
          UID 		: storagelocal.getLocalStorage('UID'),
          Token		: storagelocal.getLocalStorage('token'),
          Trigger	: 'R',
          // options : this.options,
          Route   : "count_pengerjaan",
          id_pengurusan: 2
        }

          Services.PostData(ApiService, "t_pengerjaan", mydata, async response=>{
            var responseCache = response.data[0]
            this.dPengurusanLain = responseCache
            resolve(this.dPengurusanLain);
          }, err =>{
            err
          })
      });
    },
    converterDateTime(date){
      var convDate = new Date(date)
      var dd = String(convDate.getDate()).padStart(2, '0');
      var mm = String(convDate.getMonth() + 1).padStart(2, '0'); 
      var yyyy = convDate.getFullYear();
      var h = String(convDate.getHours()).padStart(2, '0');
      var m = String(convDate.getMinutes()).padStart(2, '0');
      var s = String(convDate.getSeconds()).padStart(2, '0');

      return yyyy + '-' + mm + "-" + dd + " " + h + ":" + m + ":" + s
    },
    getDataKalenderPengerjaan(){
      this.dKalPengerjaan = []
      // var minDateConv = this.converterDateTime(this.minDate)
      // var maxDateConv = this.converterDateTime(this.maxDate)
      var maxDateConv = this.converterDateTime(this.minDate)
      return new Promise(resolve => {
        var mydata = {
          UID 		  : storagelocal.getLocalStorage('UID'),
          Token		  : storagelocal.getLocalStorage('token'),
          Trigger	  : 'R',
          // options : this.options,
          Route     : "schedule_pengerjaan",
          //tgl_mulai : minDateConv,
          tgl_akhir : maxDateConv,
        }

          Services.PostData(ApiService, "t_pengerjaan", mydata, async response=>{
            var responseCache = response.data
            this.dKalPengerjaan = responseCache

            resolve(this.dKalPengerjaan);
          }, err =>{
            err
          })
      });
    },
    getDataKalenderPengerjaan1(){
      this.dKalPengerjaan1 = []
      var minDateConv = this.converterDateTime(this.minDate)
      // var maxDateConv = this.converterDateTime(this.maxDate)
      return new Promise(resolve => {
        var mydata = {
          UID 		       : storagelocal.getLocalStorage('UID'),
          Token		       : storagelocal.getLocalStorage('token'),
          Trigger	       : 'R',
          // options : this.options,
          Route          : "schedule_pengerjaan",
          // tgl_order_start: minDateConv,
          // tgl_order_end: maxDateConv,
          tgl_akhir_pengurusan_start: minDateConv,
          // tgl_akhir_pengurusan_end: maxDateConv
        }

          Services.PostData(ApiService, "t_pengurusan_akta", mydata, async response=>{
            var responseCache = response.data
            this.dKalPengerjaan1 = responseCache

            resolve(this.dKalPengerjaan1);
          }, err =>{
            err
          })
      });
    },
    getDataKalenderPengerjaan2(){
      this.dKalPengerjaan2 = []
      var minDateConv = this.converterDateTime(this.minDate)
      // var maxDateConv = this.converterDateTime(this.maxDate)
      return new Promise(resolve => {
        var mydata = {
          UID 		       : storagelocal.getLocalStorage('UID'),
          Token		       : storagelocal.getLocalStorage('token'),
          Trigger	       : 'R',
          // options : this.options,
          Route          : "schedule_pengerjaan",
          // tgl_order_start: minDateConv,
          // tgl_order_end: maxDateConv,
          tgl_akhir_pengurusan_start: minDateConv,
          // tgl_akhir_pengurusan_end: maxDateConv
        }

          Services.PostData(ApiService, "t_pengurusan_lain", mydata, async response=>{
            var responseCache = response.data
            this.dKalPengerjaan2 = responseCache

            resolve(this.dKalPengerjaan2);
          }, err =>{
            err
          })
      });
    },
    getDataKalenderPengerjaan3(){
      this.dKalPengerjaan3 = []
      var minDateConv = this.converterDateTime(this.minDate)
      var maxDateConv = this.converterDateTime(this.maxDate)
      return new Promise(resolve => {
        var mydata = {
          UID 		       : storagelocal.getLocalStorage('UID'),
          Token		       : storagelocal.getLocalStorage('token'),
          Trigger	       : 'R',
          // options : this.options,
          Route          : "schedule_pengerjaan_akad",
          tgl_akad_start : minDateConv,
          tgl_akad_end   : maxDateConv,
          reminder       : 1,
          status         : 1
        }

          Services.PostData(ApiService, "t_pengurusan_akta", mydata, async response=>{
            var responseCache = response.data
            this.dKalPengerjaan3 = responseCache

            resolve(this.dKalPengerjaan3);
          }, err =>{
            err
          })
      });
    },
    getDataKalenderPengerjaan4(){
      this.dKalPengerjaan4 = []
      var minDateConv = this.converterDateTime(this.minDate)
      var maxDateConv = this.converterDateTime(this.maxDate)
      return new Promise(resolve => {
        var mydata = {
          UID 		       : storagelocal.getLocalStorage('UID'),
          Token		       : storagelocal.getLocalStorage('token'),
          Trigger	       : 'R',
          // options : this.options,
          Route          : "schedule_pengerjaan_akad",
          tgl_akad_start : minDateConv,
          tgl_akad_end   : maxDateConv,
          reminder       : 1,
          status         : 1
        }

          Services.PostData(ApiService, "t_pengurusan_lain", mydata, async response=>{
            var responseCache = response.data
            this.dKalPengerjaan4 = responseCache

            resolve(this.dKalPengerjaan4);
          }, err =>{
            err
          })
      });
    },
  }
};
</script>
